.search-zone[data-v-b808f49e] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 10px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.buttonbox[data-v-b808f49e] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 10px;
}
.buttonbox div[data-v-b808f49e] {
    margin: 0 10px 10px 0;
}
[data-v-b808f49e] .el-tabs__content {
  overflow: visible;
}
[data-v-b808f49e] .el-tabs__content .el-collapse {
    position: relative;
    top: -60px;
    border: 0;
}
[data-v-b808f49e] .el-collapse-item__header {
  border: 0;
}
[data-v-b808f49e] .el-collapse-item__wrap {
  border: 0;
}
[data-v-b808f49e] .el-table .el-table__row {
  background: #ffffff;
}
.subTotalSet[data-v-b808f49e] .el-transfer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.subTotalSet[data-v-b808f49e] .el-transfer .el-transfer__buttons {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.subTotalSet[data-v-b808f49e] .el-transfer .el-transfer__buttons .el-transfer__button {
      margin-left: 0;
}
