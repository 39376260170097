@charset "UTF-8";
[data-v-3363c4d1] .disableheadselection > .cell .el-checkbox__inner {
  display: none;
}
[data-v-3363c4d1] .el-table-column--selection .cell {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-weight: 400;
  height: 100%;
}
[data-v-3363c4d1] .el-tooltip {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
[data-v-3363c4d1] .el-tooltip span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
[data-v-3363c4d1] .cell div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  font-weight: 400;
}
[data-v-3363c4d1] .el-table th, .el-table th.is-leaf[data-v-3363c4d1] {
  background: #dadbdd !important;
}
[data-v-3363c4d1] .el-table--scrollable-x .el-table__body-wrapper {
  z-index: 2;
}
[data-v-3363c4d1] .el-table--fluid-height .el-table__fixed, .el-table--fluid-height .el-table__fixed-right[data-v-3363c4d1] {
  background: white;
}
[data-v-3363c4d1] .el-table--mini .el-table__cell {
  padding: 1px 0;
}
[data-v-3363c4d1] .subtotalClass {
  font-size: 14px;
  font-weight: bold;
}
[data-v-3363c4d1] ::-webkit-scrollbar {
  width: 5px !important;
  /*滚动条宽度*/
  height: 10px !important;
  /* 滚动条高度 */
}
