.tags-bar[data-v-33a883bd] {
  width: 100%;
  height: 34px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  background-color: #fff;
}
.tags-bar .scrollTag[data-v-33a883bd] {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    overflow: hidden;
}
.tags-bar .scrollTag .tags[data-v-33a883bd] {
      display: inline-block;
      white-space: nowrap;
      line-height: 34px;
      -webkit-transition: all 0.3s ease;
      transition: all 0.3s ease;
}
.tags-bar .scrollTag .tags .tags-item[data-v-33a883bd] {
        padding: 6px 8px;
        margin-right: 4px;
        font-size: 14px;
        background-color: #f4f4f5;
        color: #909399;
        border: 1px solid #e9e9eb;
        border-radius: 4px;
        position: relative;
}
.tags-bar .scrollTag .tags .tags-item[data-v-33a883bd]:not(.active):after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 0;
          height: 2px;
          background: #1397fb;
          -webkit-transition: width 0.3s ease;
          transition: width 0.3s ease;
}
.tags-bar .scrollTag .tags .tags-item[data-v-33a883bd]:not(.active):hover::after {
          width: 100%;
          height: 3px;
          background: #1397fb;
}
.tags-bar .scrollTag .tags .tags-item .el-icon-close[data-v-33a883bd] {
          color: #909399;
          width: 14px;
          height: 14px;
          text-align: center;
          line-height: 14px;
}
.tags-bar .scrollTag .tags .tags-item .el-icon-close[data-v-33a883bd]:hover {
            color: #fff;
            background-color: #909399;
            border-radius: 50%;
}
.tags-bar .scrollTag .tags .active[data-v-33a883bd] {
        color: #409eff;
        -webkit-box-shadow: 1px 0 2px #909399;
                box-shadow: 1px 0 2px #909399;
}
.tags-bar .btns_nav[data-v-33a883bd] {
    padding: 0 4px;
    width: 30px;
    text-align: center;
    z-index: 9;
    background-color: #fff;
    overflow: hidden;
    font-size: 20px;
    line-height: 34px;
    -webkit-box-shadow: 0 0 10px #888888;
            box-shadow: 0 0 10px #888888;
}
.tags-bar .btns_nav[data-v-33a883bd]:hover {
      color: #1397fb;
      cursor: pointer;
}
