.shop_wrapper[data-v-1561afd7] {
  border: 0px solid black;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  -ms-flex-line-pack: start;
      align-content: flex-start;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  width: 1000px;
  margin: 0vw auto;
}
.shop_item_bg[data-v-1561afd7] {
  background-color: #f7f7f7;
}
.shop_item[data-v-1561afd7] {
  border: 0px solid black;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-line-pack: start;
      align-content: flex-start;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  width: 240px;
  float: left;
  margin: 0vw 10px 10px 0vw;
  overflow: hidden;
  position: relative;
  border-radius: 8px;
  height: 334px;
}
.shop_item_link[data-v-1561afd7] {
  display: block;
  position: relative;
  text-decoration: none;
  height: 334px;
}
.rax-image[data-v-1561afd7] {
  width: 240px;
  height: 240px;
  display: block;
}
.shop_main-wrapper[data-v-1561afd7] {
  border: 0px solid black;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-line-pack: start;
      align-content: flex-start;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  overflow: hidden;
  width: 240px;
  height: 94px;
  margin: 0vw auto;
  background-color: #ffffff;
}
.shop__main-title[data-v-1561afd7] {
  border-width: 0px;
  border-style: solid;
  border-color: black;
  position: relative;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: block;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-line-pack: start;
      align-content: flex-start;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  font-size: 16px;
  white-space: nowrap;
  width: 216px;
  height: 21px;
  line-height: 21px;
  color: #333333;
  margin: 0px auto 0vw;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
}
.noimage[data-v-1561afd7] {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-weight: 600;
  background-image: -webkit-gradient(linear, left bottom, left top, from(#e14fad), to(#f9d423));
  background-image: linear-gradient(to top, #e14fad 0%, #f9d423 100%);
  -webkit-background-clip: text;
          background-clip: text;
  color: transparent;
}
.noimage i[data-v-1561afd7] {
    font-size: 45px;
    margin-bottom: 15px;
}
