.header-search[data-v-032bd1f0] {
  font-size: 0 !important;
}
.header-search .search-icon[data-v-032bd1f0] {
    cursor: pointer;
    font-size: 18px;
    vertical-align: middle;
}
.header-search .header-search-select[data-v-032bd1f0] {
    font-size: 18px;
    -webkit-transition: width 0.2s;
    transition: width 0.2s;
    width: 0;
    overflow: hidden;
    background: transparent;
    border-radius: 0;
    display: inline-block;
    vertical-align: middle;
}
.header-search .header-search-select[data-v-032bd1f0] :deep(.el-input__inner) {
      border-radius: 0;
      border: 0;
      padding-left: 0;
      padding-right: 0;
      -webkit-box-shadow: none !important;
              box-shadow: none !important;
      border-bottom: 1px solid #d9d9d9;
      vertical-align: middle;
}
.header-search.show .header-search-select[data-v-032bd1f0] {
    width: 210px;
    margin-left: 10px;
}
