.breadcrumb-enter-active,
.breadcrumb-leave-active {
  -webkit-transition: all 0.5s;
  transition: all 0.5s; }

.breadcrumb-enter,
.breadcrumb-leave-active {
  opacity: 0;
  -webkit-transform: translateX(20px);
          transform: translateX(20px); }

.breadcrumb-move {
  -webkit-transition: all 0.5s;
  transition: all 0.5s; }

.breadcrumb-leave-active {
  position: absolute; }

/*  */
.fade-transform-leave-active,
.fade-transform-enter-active {
  -webkit-transition: all 0.3s;
  transition: all 0.3s; }

.fade-transform-enter {
  opacity: 0;
  -webkit-transform: translateX(-30px);
          transform: translateX(-30px); }

.fade-transform-leave-to {
  opacity: 0;
  -webkit-transform: translateX(30px);
          transform: translateX(30px); }

/* 
logo
*/
.sidebarLogoFade-enter-active {
  -webkit-transition: opacity 1.5s;
  transition: opacity 1.5s; }

.sidebarLogoFade-enter,
.sidebarLogoFade-leave-to {
  opacity: 0; }

.el-col:last-child {
  margin-bottom: 0; }

.el-tree {
  border-radius: 10px; }
  .el-tree .custom-tree-node {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    font-size: 14px;
    padding-right: 8px; }

.el-dialog .el-dialog__header {
  padding: 10px 20px; }

.el-dialog .el-dialog__body {
  padding: 10px 20px;
  color: #606266;
  font-size: 14px;
  word-break: break-all; }

.el-dialog .dialog-footer {
  display: inline-block;
  width: 100%;
  text-align: end; }

.el-form {
  width: 100%; }
  .el-form .el-form-item {
    margin: 0;
    width: 100%; }
    .el-form .el-form-item .el-form-item__content {
      width: calc(100% - 90px); }
      .el-form .el-form-item .el-form-item__content .el-select,
      .el-form .el-form-item .el-form-item__content .el-input {
        width: 100%; }
      .el-form .el-form-item .el-form-item__content .el-date-editor .el-input__inner {
        margin-right: -30px; }
      .el-form .el-form-item .el-form-item__content .el-select .el-input__inner {
        margin-right: -15px; }
      .el-form .el-form-item .el-form-item__content .el-input.is-disabled .el-input__inner {
        color: black;
        background: #d4d4d4cc; }

body .el-table th.gutter {
  display: table-cell !important; }

body .el-table colgroup.gutter {
  display: table-cell !important; }

.el-card .el-card__body {
  padding: 4px; }

/* 
   input
*/
.el-range-editor.el-input__inner {
  padding: 3px 5px;
  width: 260px; }
  .el-range-editor.el-input__inner .el-range-separator {
    padding: 0; }

/* 
table */
.el-table__body tr.current-row > td {
  background-color: #73e3f7; }

/* 
tab */
.el-tabs .el-tabs__header {
  margin: 0 0 10px; }

.el-row {
  margin-bottom: 14px; }
  .el-row:last-child {
    margin-bottom: 0; }

.el-table--border::after, .el-table--group::after, .el-table::before, .el-table__fixed::before {
  background-color: transparent; }

.el-table th > .cell {
  white-space: nowrap;
  text-indent: 10px;
  color: #706d6d; }

.el-table .sort-caret.descending {
  border-top-color: #706d6d; }

.el-table .sort-caret.ascending {
  border-bottom-color: #706d6d; }

.el-table th:first-child > .cell {
  text-indent: 0px; }

.el-table--mini td, .el-table--mini th {
  padding: 0;
  height: 36px; }

.el-table .cell {
  padding-right: 0px; }

.el-input--mini .el-input__inner {
  height: 28px; }

.el-input--mini {
  font-size: 14px; }

.el-table td div {
  width: 100%;
  height: 100%; }

.el-table td div .el-input--mini .el-input__inner {
  height: 100%; }

.el-row::after, .el-row::before {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 12px; }

.el-tooltip span {
  color: black; }

.el-table td.el-table__cell div {
  width: 100%; }

.el-range-editor--mini.el-input__inner {
  height: 24px;
  line-height: 28px; }

.el-table .cell.el-tooltip {
  width: auto !important;
  padding-right: 5px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }

.el-form-item--mini .el-form-item__content, .el-form-item--mini .el-form-item__label {
  line-height: 30px; }

.sidebar {
  -webkit-transition: width 0.28s;
  transition: width 0.28s;
  width: 210px;
  background-color: #304156;
  height: 100%;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  overflow: hidden; }
  .sidebar .el-scrollbar {
    height: 96%;
    overflow: hidden;
    overflow-y: scroll; }
    .sidebar .el-scrollbar .el-menu {
      border-right: 0px; }
  .sidebar .el-scrollbar::-webkit-scrollbar {
    display: none;
    /* Chrome Safari */ }
  .sidebar .hxlogo {
    height: 50px;
    text-align: center;
    background-color: #2b2f3a;
    line-height: 50px; }
    .sidebar .hxlogo a {
      height: 100%;
      width: 100%; }
      .sidebar .hxlogo a img {
        width: 40px;
        height: 40px;
        vertical-align: middle; }
      .sidebar .hxlogo a h1 {
        margin-left: 12px;
        color: #fff;
        font-weight: 600;
        line-height: 50px;
        font-size: 14px;
        font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
        vertical-align: middle; }
    .sidebar .hxlogo a,
    .sidebar .hxlogo img,
    .sidebar .hxlogo h1 {
      display: inline-block; }

.hideSidebar .sidebar {
  width: 50px; }

.hideSidebar .el-submenu {
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center; }
  .hideSidebar .el-submenu .el-submenu__title {
    padding: 0 !important;
    text-align: center; }
    .hideSidebar .el-submenu .el-submenu__title .sub-el-icon {
      margin-right: 0px; }
    .hideSidebar .el-submenu .el-submenu__title .el-submenu__icon-arrow {
      display: none; }

.hideSidebar .el-menu--collapse {
  width: 50px; }
  .hideSidebar .el-menu--collapse .el-submenu > .el-submenu__title > span {
    height: 0;
    width: 0;
    overflow: hidden;
    visibility: hidden;
    display: inline-block; }

.section {
  width: 100%;
  height: 100%;
  background-color: #fff;
  overflow: hidden; }
  .section header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-shadow: 0 3px 4px rgba(0, 21, 41, 0.08);
            box-shadow: 0 3px 4px rgba(0, 21, 41, 0.08); }
    .section header .nav-bar {
      height: 50px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      position: relative;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between;
      background: #fff;
      z-index: 10;
      -webkit-box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
              box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
      padding-right: 30px; }
      .section header .nav-bar .nav-bar-left {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center; }
    .section header .tag-bar {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center; }
      .section header .tag-bar .tags-bar {
        border-right: 1px dashed #ccc; }
      .section header .tag-bar .el-dropdown {
        padding: 0 4px; }
        .section header .tag-bar .el-dropdown i {
          -webkit-transform: rotate(90deg);
                  transform: rotate(90deg);
          font-size: 20px; }
  .section main {
    height: calc(100vh - 84px);
    padding: 10px; }

.table-handle {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  .table-handle i {
    font-size: 16px;
    cursor: pointer; }
    .table-handle i:hover {
      -webkit-transform: scale(1.1);
              transform: scale(1.1); }

.vue-treeselect .vue-treeselect__control {
  height: var(--height); }

body {
  height: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif;
  overflow: hidden; }

html {
  height: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  overflow: hidden; }

#app {
  height: 100%; }

*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
          box-sizing: inherit;
  margin: 0;
  padding: 0; }

.no-padding {
  padding: 0px !important; }

a:focus,
a:active {
  outline: none; }

a,
a:focus,
a:hover {
  cursor: pointer;
  color: inherit;
  text-decoration: none; }

::-webkit-scrollbar-track-piece {
  background-color: transparent; }

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background-color: transparent; }

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: rgba(144, 146, 152, 0.3); }
