@charset "UTF-8";
.hhh[data-v-cba19312] {
  border-bottom: 1px solid #606266;
  min-height: 28px;
}
[data-v-cba19312] .el-row {
  margin-bottom: 0;
}
[data-v-cba19312] .el-form-item__label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
[data-v-cba19312] .el-form-item__label /deep/.el-input:hover {
    color: red;
}
[data-v-cba19312] .el-input.is-disabled .el-input__inner {
  cursor: default !important;
}
.coin[data-v-cba19312] {
  cursor: pointer;
}
[data-v-cba19312] .tArea {
  font-size: 18px;
}
[data-v-cba19312] .tArea textarea {
    height: 200px;
    overflow: scroll;
    white-space: nowrap;
}
[data-v-cba19312] .el-col {
  margin-right: 20px;
  margin-bottom: 15px;
}
[data-v-cba19312] .el-form-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
[data-v-cba19312] .el-form-item .el-form-item__content {
    margin-left: 0 !important;
}
.rowBox[data-v-cba19312] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

/* 去掉number类型自带的加减按钮 */
[data-v-cba19312] input::-webkit-outer-spin-button,[data-v-cba19312] input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
[data-v-cba19312] input[type='number'] {
  -moz-appearance: textfield !important;
}
[data-v-cba19312] .is-required span {
  color: red !important;
}
[data-v-cba19312] .is-required i {
  color: #b1b1b1 !important;
}
[data-v-cba19312] .el-dialog__body {
  overflow: auto;
  max-height: 700px;
}
